html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0f1323;
  color: #fff;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 7px;
  height: 0;
}

*::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb {
  background: #00775d;
  border-radius: 10px;
  box-shadow: none;
}

*::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.55);
}
